<template>
  <div class="legal-page-template-card">
    <material-app-card
      :color="color"
      icon="mdi-folder-open-outline"
      title="LEGAL TEMPLATES"
    >
      <!-- <div class="intro mb-4">
        For reference only, for project based client project contract process please use <span class="bold">Create New Request</span> in Client Project Contract Card!
      </div> -->
      <div class="content mb-3">
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="list"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item="props">
            <tr>
              <td style="text-align:center;">
                <!-- <v-img v-if="props.item.icon" :src="props.item.icon" /> -->
                <v-icon v-if="props.item.folder" color="orange lighten-3">
                  mdi-folder
                </v-icon>
                <v-icon v-else color="blue lighten-1">
                  mdi-file-document-outline
                </v-icon>
              </td>
              <td style="text-align:left;">
                <div class="d-flex align-center" style="height:100%">
                  <a
                    class="grey--text text--darken-2 text-decoration-underline"
                    target="_new" 
                    :href="props.item.url" 
                  >
                    {{ props.item.name }}
                  </a>
                </div>
              </td>
              <td style="text-align:left;color:grey;">
                {{ props.item.lastModTimeFormatted }}
              </td>
            </tr>
          </template>
        </v-data-table>
        
        <div v-if="loading" class="loading">
          <v-progress-circular
            :width="4"
            color="grey"
            indeterminate
            :size="24"
          ></v-progress-circular>
          <div
            class="grey--text text--lighten-1 mt-1"
          >
            Loading
          </div>
        </div>
      </div>
      <div v-if="!loading" class="view-more mt-3 mb-n3">
        <v-spacer></v-spacer>
        <v-btn color="primary" text
          @click="viewMore"
        >
          View More >>
        </v-btn>
      </div>
    </material-app-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      loading: true,
      uri: '',
      list: [],
      headers: [
        {
          text: ' ',
          sortable: false,
          align: 'center',
          width: '45px'
        },
        {
          text: 'Title',
          sortable: false
        },
        {
          text: 'Last Modified',
          sortable: false,
          width: '160px',
        }
      ]
    }
  },
  computed: {
    ...mapGetters('user', [
      'loginStatus'
    ]),
  },
  methods: {
    // action
    viewMore () {
      window.open(this.uri)
    },

    // function
    async getData () {
      try {
        const res = await xPost(
          'gfunc',
          'getflbyls',
          {
            loginStatus: this.loginStatus,
            f: 'LegalTemplates'
          }
        )
        if (res.data.status === 200) {
          this.uri = res.data.uri
          this.list = res.data.list.map(el => ({
            ...el,
            lastModTimeFormatted: 
              el.lastModTime ?
              el.lastModTime.substring(0, 10)
               + ' '
               + el.lastModTime.substring(11, 16)
              : '',
          }))
          this.loading = false
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              // this.$router.push('/login')
              this.$emit('auth-fail')
              throw 'Authorize Failed'
            }
          } catch (e) {}
          throw {
            response: {
              status: res.data.status
            }
          }
        }
      } catch (err) {
        console.log('Error [legal template data]: ')
        // console.log(err)
      }
    }
  },
  created () {
    this.getData()
  }
}
</script>

<style lang="sass">
.legal-page-template-card
  height: calc(100% - 48px)
  text-align: left

  .bold
    font-weight: bold
  .v-card
    height: 100%
    
    .v-card__text
      padding-bottom: 55px
      height: calc(100% - 64px)
      position: relative

  .content
    width: 100%
    overflow-x: auto
    text-align: center

  .view-more
    position: absolute
    right: 16px
    bottom: 16px
    display: flex

    .v-btn
      margin: 0 !important
      padding: 1px 6px !important

  .loading
    padding-top: 100px
    height: 215px

</style>
