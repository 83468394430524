<template>
  <v-layout wrap fill-height>
    <v-flex
      xs12
      pt-0 pb-0
    >
      <legal-page-template-card>
      </legal-page-template-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import LegalPageTemplateCard from "@/components/app/legal-page/template-card";

export default {
  components: {
    LegalPageTemplateCard
  },
  methods: {
    ...mapActions('app', ['setMainTitle'])
  },
  created () {
    this.setMainTitle('Legal Template')
  }
}
</script>

<style>

</style>
